import Vue from "vue/dist/vue.js";

// Strip containing tag from html
export default {
  name: "html-fragment",
  functional: true,
  props: ["html"],
  render(h, ctx) {
    const nodes = new Vue({
      beforeCreate() {
        this.$createElement = h;
      }, // not necessary, but cleaner
      template: `<div>${ctx.props.html}</div>`,
    }).$mount()._vnode.children;
    return nodes;
  },
};
